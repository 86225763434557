import {Col, Form, Row} from 'react-bootstrap';
import {Ticket} from '../../../../../model/Ticket';
import {renderFormProps} from '../../../../FormController';
import React, {useEffect} from 'react';
import RadioInput from '../../../../fields/RadioInput';
import {ControlImportActions, DossierImportActions,} from '../../../../../components/app/App/DossierImportActions';
import {formatDate} from '../../../../../utils';
import {TransportationModeConstants} from '../../../../../constants/model';
import TextInput from '../../../../fields/TextInput';
import {ControlSwitchInput} from "../../../../../components/SwitchButton";
import ConditionalInput from "../../../../fields/ConditionalInput/ConditionalInput";
import SelectInput, {SelectInputOptions} from "../../../../fields/SelectInput";
import {Shipment} from "../../../../../model/Shipment";
import NumberInput from "../../../../fields/NumberInput";

interface MainTransportProps extends renderFormProps<Ticket> {
    shipment: Shipment,
}

const dateOption = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};

const currencyChoices = [
    {
        label: 'CAD',
        value: '8'
    },
    {
        label: 'CNY',
        value: '12'
    },
    {
        label: 'EUR',
        value: '19'
    },
    {
        label: 'USD',
        value: '61'
    },
    {
        label: 'ZAR',
        value: '65'
    },
]


const getMightContainADateLabel = (label: string, shipmentAttribute: Date | null, alternativeLabel: string, withTime = true) => {
    if (shipmentAttribute) {
        const text = `${label} ${formatDate(new Date(shipmentAttribute), 'fr-FR', dateOption, 'date')}`;
        return text.concat(
            ' ',
            `à ${formatDate(new Date(shipmentAttribute), 'fr-FR', dateOption, 'time')}`
                .repeat(Number(withTime))
        );
    }
    return alternativeLabel;
}

export const MainTransport = ({
    register,
    resource,
    control,
    shipment,
    getValues,
    setValue
}: MainTransportProps) => {
    const [show, setShow] = React.useState<boolean>(false);
    const actions: ControlImportActions[] = [];

    const incoterms = shipment.incoterms?.name;
    const customeo = typeof shipment.customeos[0] === 'string' ? null : shipment.customeos[0]

    useEffect(() => {
        setShow(['FOB', 'EXW'].includes(incoterms));
    }, [incoterms]);

    useEffect(() => {
        const baeVal = getValues('bae')
        const basiVal = getValues('bas')
        const quaysideViewVal = getValues('quaysideView')
        const copyViewVal = getValues('copy')
        const originalsViewVal = getValues('originals')
        const preparationVal = getValues('preparation')
        setValue('bae', !!(customeo && customeo.baeClearedDate) || baeVal);
        setValue('bas', !!(shipment.basiDate) || basiVal);
        setValue('quaysideView', !!(shipment.vaqDate) || quaysideViewVal);
        setValue('copy', !!(shipment.copiesReceivedOn) || copyViewVal);
        setValue('originals', !!(shipment.originalsReceivedOn) || originalsViewVal);
        setValue('preparation', !!(customeo && customeo.preparation) || preparationVal);

    }, [customeo, setValue, getValues, shipment]);

    actions.push(
        {
            label: shipment.copiesReceivedOn ? 'Copie le' : 'Copie ?',
            name: 'copy',
            switchOnChildren: [
                {
                    label: getMightContainADateLabel('', shipment.copiesReceivedOn, 'Date de copie', false),
                    name: 'copyDate',
                    value: resource.copyDate,
                    type: shipment.copiesReceivedOn ? 'label' : 'date'
                }
            ],
            type: resource.copyDate || shipment.copiesReceivedOn  ? 'label' : 'bool',
        },
        {
            label: 'Express release ?',
            name: 'expressRelease',
        }
    )

    if (shipment.originalsReceivedOn) {
        actions.push(
            {
                label: 'Originaux reçus le',
                name: 'originals',
                switchOnChildren: [
                    {
                        label: getMightContainADateLabel('', shipment.originalsReceivedOn, '', false),
                        name: 'originalsDate',
                        type: 'label'
                    }
                ],
                type: 'label',
            }
        )
    } else {
        actions.push(
            {
                label: 'Demande originaux au client\u00A0?',
                name: 'requestOriginalsToCustomer',
                switchOnChildren: [
                    {
                        label: 'Originaux\u00A0?',
                        name: 'originals',
                        switchOnChildren: [
                            {
                                name: 'originalsDate',
                                type: 'date'
                            }
                        ]
                    }
                ]
            }
        )
    }

    if (customeo?.preparation) {
        actions.push(
            {
                label: 'Prépa Douane',
                name: 'preparation',
                switchOnChildren: [
                    {
                        label: getMightContainADateLabel('', customeo?.createdAt, 'Date de prépa douane'),
                        name: 'createdAt',
                        type: 'label',
                    },
                ],
                type: 'label'
            }
        )
    } else {
        actions.push(
            {
                label: 'Prépa Douane ?',
                name: 'customsPreparation',
                switchOnChildren: [
                    {
                        label: getMightContainADateLabel('', resource.customsPreparationDate, 'Date de prépa douane'),
                        name: 'customsPreparationDate',
                        type: 'datetime-local',
                    },
                ],
                type:  resource.customsPreparationDate ? 'label' : 'bool'
            }
        )
    }

    actions.push(
        {
            label: 'Création DOSS CCS ?',
            name: 'dossCcsCreation',
            value: resource.dossCcsCreationDate ? true : resource.dossCcsCreation,
            switchOnChildren: [
                {
                    label: getMightContainADateLabel('', resource.dossCcsFFCreationDate, 'Date CCS'),
                    name: 'dossCcsCreationDate',
                    value: resource.dossCcsCreationDate,
                    type: resource.dossCcsFFCreationDate ? 'label' : 'datetime-local',
                },
            ],
            type: resource.dossCcsCreationDate ? 'label' : 'bool'
        },
        {
            label: 'Dépôt BL + paiement cie ?',
            name: 'originalsDocumentDepositedToCompanyAndPayment',
            value: resource.originalsDocumentDepositedToCompanyAndPayment,
        }
    )

    if(resource.badCompany || resource.badCompanyRequest || !!resource.badCompanyReceivedOn) {
        actions.push(
            {
                name: 'badCompany',
                label: resource.badCompanyReceivedOn || resource.badCompanyDate ? 'Date BAD ' : 'BAD compagnie\u00A0?',
                value: resource.badCompanyReceivedOn ? true : resource.badCompany,
                switchOnChildren: [
                    {
                        label: getMightContainADateLabel('', resource.badCompanyReceivedOn, ''),
                        name: 'badCompanyDate',
                        value: resource.badCompanyDate,
                        type: resource.badCompanyReceivedOn ? 'label' : 'datetime-local'
                    }
                ],
                type: resource.badCompanyDate || resource.badCompanyReceivedOn ? 'label' : 'bool',
            }
        )
    } else {
        actions.push(
            {
                label: 'Demande BAD\u00A0?',
                name: 'badCompanyRequest',
                value: resource.badCompanyRequest,
                switchOnChildren: [
                    {
                        label: 'Date BAD',
                        name: 'badCompany',
                        value: resource.badCompany,
                        switchOnChildren: [
                            {
                                name: 'badCompanyDate',
                                value: resource.badCompanyDate,
                                type: 'datetime-local'
                            }
                        ]
                    }
                ]
            }
        )
    }

    actions.push(
        {
            label: 'Transfert sous douane ?',
            name: 'customsTransfer',
            value: resource.customsTransfer,
        },
        {
            label: 'Douane anticipée ?', // readonly sur le fichier de correspondance
            name: 'isAheadOfTime',
            value: false,
            type: 'label',
            switchOffChildren: [
                {
                    label: customeo?.aheadOfTime ? 'Oui' : 'Non',
                    type: 'label',
                    name: 'aheadOfTime'
                }
            ]
        }
    );

    actions.push(
        {
            label: 'Vu à quai ?',
            name: 'quaysideView',
            switchOnChildren: [
                {
                    label: getMightContainADateLabel('', shipment.vaqDate, 'Date de vu à quai'),
                    name: 'quaysideViewDate',
                    type: shipment.vaqDate ? 'label' : 'datetime-local',
                },
            ],
            type: shipment.vaqDate || resource.quaysideViewDate ? 'label' : 'bool'
        }
    )

    const clearanceAction: ControlImportActions[] = [
        {
            label: 'Lancer le “Ok for clearance” de MyCustoms ?', // readonly sur le fichier de correspondance
            name: 'isTriggerMyCustomsOkForClearance',
            value: false,
            type: 'label',
            switchOffChildren: [
                {
                    label: customeo?.clearance ? 'Oui' : 'Non',
                    type: 'label',
                    name: 'triggerMyCustomsOkForClearance'
                }
            ]
        }
    ]

    const radioActions: ControlImportActions[] = [
        {
            label: 'Visite douane ?',
            name: 'requestCustomsInspection',
            value: resource.requestCustomsInspection,
        },
        {label: 'Sycoscan ?', name: 'sycoscan', value: resource.sycoscan},
    ]

    const afterRadioActions: ControlImportActions[] = [
        {
            label: 'BAE ?',
            name: 'bae',
            switchOnChildren: [
                {
                    label: getMightContainADateLabel('', customeo?.baeClearedDate ?? null, 'Date BAE'),
                    name: 'baeDate',
                    type: customeo?.baeClearedDate ? 'label' : 'datetime-local',
                },
            ],
            type: customeo?.baeClearedDate || resource.baeDate ? 'label' : 'bool'
        },
        {
            label: 'Id TPT',
            name: 'isIdTPT',
            value: resource.isIdTPT,
            switchOnChildren: [
                {
                    label: 'Date TPT',
                    name: 'idTPT',
                    value: resource.idTPT,
                    type: 'datetime-local'
                },
            ],
            type: resource.idTPT ? 'label' : 'bool'
        },
        {
            label: 'CRV',
            name: 'isCrv',
            value: resource.isCrv,
            switchOnChildren: [
                {
                    label: 'Date CRV',
                    name: 'crv',
                    value: resource.crv,
                    type: 'datetime-local'
                },
            ],
            type: resource.crv ? 'label' : 'bool'
        },
        {
            label: 'TCT',
            name: 'isTct',
            value: resource.isTct,
            switchOnChildren: [
                {
                    label: 'Numéro TCT',
                    name: 'tct',
                    value: resource.tct,
                    type: 'text'
                },
            ],
            type: resource.tct ? 'label' : 'bool'
        },
        {
            label: 'BAS ?',
            name: 'bas',
            switchOnChildren: [
                {
                    label: getMightContainADateLabel('', shipment.basiDate, 'Date BAS'),
                    name: 'basDate',
                    type: shipment.basiDate ? 'label' : 'datetime-local',
                },
            ],
            type: shipment.basiDate || resource.basDate ? 'label' : 'bool'
        },
    ];

    const getTrafficModeOptions = () => {
        const options: SelectInputOptions[] = [];
        Object.entries(TransportationModeConstants).forEach(([,value]) => {
            switch (value) {
                case TransportationModeConstants.ROAD:
                    options.push({label: 'Routier', value: value})
                    break;
                case TransportationModeConstants.SEA:
                    options.push({label: 'Maritime', value: value})
                    break;
                case TransportationModeConstants.AIR:
                    options.push({label: 'Aérien', value: value})
                    break;
                default:
                    options.push({label: 'Ferroviaire', value: value})
                    break;
            }
        })
        return options;
    }

    return (
        <div className={'border-top ms-0 me-0 pt-3 pb-5'}>
            <Row>
                <Col lg={8}>
                    <h2>Transport Principal</h2>
                    <div className={'d-flex flex-column h-100 justify-content-between pb-5'}>
                        <div>
                            <Row>
                                <Col lg={4}>
                                    <SelectInput
                                        label={'Mode de transport principal'}
                                        name={'shipment.transportationMode'}
                                        singleValueClasses={'text-primary'}
                                        control={control}
                                        selectOptions={getTrafficModeOptions()}
                                    />
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        N° de vol, train, Nom du navire, Camion
                                    </p>
                                    <p>
                                        {shipment.trafficIdentification ?? '-'}
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        N° de VOY
                                    </p>
                                    <p>
                                        {shipment.travelNumber ?? '-'}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        Lieu de chargement
                                    </p>
                                    <p>
                                        {shipment.loadingPlatform?.name ?? '-'} ({shipment.loadingPlatform?.country ?? '-'})
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        ETD
                                    </p>
                                    <p>
                                        {shipment.ETD ?
                                            formatDate(shipment.ETD, 'fr-FR', {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit"
                                            }) : '-'
                                        }
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        ATD
                                    </p>
                                    <p>
                                        {shipment.ATD ?
                                            formatDate(shipment.ATD, 'fr-FR', {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit"
                                            }) : '-'
                                        }
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        Lieu de déchargement
                                    </p>
                                    <p>
                                        {shipment.unloadingPlatform?.name ?? '-'} ({shipment.unloadingPlatform?.country ?? '-'})
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    <Row>
                                        <Col>
                                            {/* readonly sur fichier correspondance */}
                                            <p className={'label'}>
                                                ETA
                                            </p>
                                            <p className={'text-primary'}>
                                                {shipment.ETA ?
                                                    formatDate(shipment.ETA, 'fr-FR', {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit"
                                                    }) : '-'
                                                }
                                            </p>
                                        </Col>
                                        {
                                            shipment.PRD ?
                                                <Col className={'text-success'}>
                                                    <p className={'label'}>
                                                        PRD
                                                    </p>
                                                    <p>
                                                        {formatDate(shipment.PRD, 'fr-FR', {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            day: "2-digit"
                                                        })}
                                                    </p>
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        ATA
                                    </p>
                                    <p>
                                        {shipment.ATA ?
                                            formatDate(shipment.ATA, 'fr-FR', {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit"
                                            }) : '-'
                                        }
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        Compagnie
                                    </p>
                                    <p>
                                        {shipment.imposedCompany?.name ?? '-'}
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        LTA / CMR / BL
                                    </p>
                                    <p>
                                        {shipment.blLtaCmr ?? '-' }
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    {/* readonly sur fichier correspondance */}
                                    <p className={'label'}>
                                        Numéro de booking
                                    </p>
                                    <p>
                                        {shipment.bookingNumber ?? '-'}
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    <TextInput
                                        name={'shipment.filingNumber'}
                                        label={'Numéro de filing'}
                                        control={control}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <p className={'label'}>
                                        Terminal / Entrepôt / Magasin
                                    </p>
                                    <p>
                                        {shipment.warehouse ?? '-'}
                                    </p>
                                </Col>
                                <Col lg={4}>
                                    <TextInput
                                        name={'shipment.transportFeNumber'}
                                        label={'Numéro de FE'}
                                        control={control}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <TextInput
                                        name={'shipment.transportIcvNumber'}
                                        label={'Numéro BBI'}
                                        control={control}
                                    />

                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className={show ? '' : 'd-none'}>
                        {DossierImportActions([
                            {
                                label: 'Demande de booking à l\'agent ?',
                                name: 'bookingRequest',
                                value: resource.bookingRequest,
                            },
                            {
                                label: 'Prévision d\'embarquement au client ?',
                                name: 'customerBoardingForecast',
                                value: resource.customerBoardingForecast,
                            },
                            {
                                label: 'Confirmation d\'embarquement au client ?',
                                name: 'customerBoardingConfirmation',
                                value: resource.customerBoardingConfirmation,
                            }
                        ], register, control)}
                    </div>
                    {
                        DossierImportActions(
                            actions,
                            register,
                            control,
                        )
                    }
                </Col>
            </Row>

            <Row>
                <Col lg={8}>
                    <h4>Frais à l'arrivée</h4>
                    <Row>
                        <Col lg={4}>
                            <p className={'label'}>
                                Inland cost
                            </p>
                            <Row>
                                {
                                    customeo
                                        ? (
                                            <>
                                                <Col>
                                                    {
                                                        customeo.inlandCost
                                                            ? customeo.inlandCost  + ' ' + customeo.inlandCurrency?.shortName ?? ''
                                                            : '-'
                                                    }
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col lg={7}>
                                                    <NumberInput
                                                        name={'customeoInlandCost'}
                                                        control={control}
                                                    />
                                                </Col>
                                                <Col lg={5}>
                                                    <SelectInput
                                                        name={'customeoInlandCostCurrency'}
                                                        control={control}
                                                        selectOptions={currencyChoices}
                                                        isClearable={false}
                                                    />
                                                </Col>
                                            </>
                                        )
                                }
                            </Row>
                        </Col>
                        <Col lg={4}>
                            <p className={'label'}>
                                Freight charges
                            </p>
                            <Row>
                                {
                                    customeo
                                        ? (
                                            <>
                                                <Col>
                                                    {
                                                        customeo.freightCharges
                                                            ? customeo.freightCharges  + ' ' + customeo.freightChargesCurrency?.shortName ?? ''
                                                            : '-'
                                                    }
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col lg={7}>
                                                    <NumberInput
                                                        name={'customeoFreightCharges'}
                                                        control={control}
                                                    />
                                                </Col>
                                                <Col lg={5}>
                                                    <SelectInput
                                                        name={'customeoFreightChargesCurrency'}
                                                        control={control}
                                                        selectOptions={currencyChoices}
                                                        isClearable={false}
                                                    />
                                                </Col>
                                            </>
                                        )
                                }
                            </Row>
                        </Col>
                        <Col lg={4}>
                            <p className={'label'}>
                                Insurance amount
                            </p>
                            <Row>
                                {
                                    customeo
                                        ? (
                                            <>
                                                <Col>
                                                    {
                                                        customeo.insuranceAmount
                                                            ? customeo.insuranceAmount  + ' ' + customeo.insuranceAmountCurrency?.shortName ?? ''
                                                            : '-'
                                                    }
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col lg={7}>
                                                    <NumberInput
                                                        name={'customeoInsuranceAmount'}
                                                        control={control}
                                                    />
                                                </Col>
                                                <Col lg={5}>
                                                    <SelectInput
                                                        name={'customeoInsuranceAmountCurrency'}
                                                        control={control}
                                                        selectOptions={currencyChoices}
                                                        isClearable={false}
                                                    />
                                                </Col>
                                            </>
                                        )
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ControlSwitchInput
                                label={'Emettre des réserves ?'}
                                control={control}
                                name={'isReserved'}
                                value={resource.isReserved}
                            />
                            <ConditionalInput
                                control={control}
                                fieldName={'isReserved'}
                                renderField={
                                    () => <>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            {...register('reserved')}
                                            defaultValue={resource.reserved ?? ''}
                                        />
                                    </>
                                }
                                fieldValue={true}
                                defaultValue={!!resource.reserved}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col lg={4}>
                    {DossierImportActions(clearanceAction, register, control)}
                    <Row className={'mb-2 actions-row'}>
                        <Col>
                            {
                                DossierImportActions(
                                    radioActions,
                                    register,
                                    control,
                                )
                            }
                        </Col>
                        <Col>
                            <p className={'fw-bold'}>Contrôle ?</p>
                            <RadioInput name={'control'} fieldValue={'sivep'}
                                        label={'SIVEP'}
                                        control={control} containerClass={'mb-1'}/>
                            <RadioInput name={'control'} fieldValue={'hsa'}
                                        label={'HSA'}
                                        control={control} containerClass={'mb-1'}/>
                            <RadioInput name={'control'} fieldValue={'bio'}
                                        label={'BIO'}
                                        control={control} containerClass={'mb-1'}/>
                        </Col>
                    </Row>
                    {DossierImportActions(afterRadioActions, register, control)}
                </Col>
            </Row>
        </div>
    );
};
