import {normalizedType, Normalizer, serializedType} from "./normalizer";
import {Shipment} from "../../model/Shipment";
import {ShipmentEntrustWithConstants, ShipmentIncotermsConstants} from "../../constants/model";
import {incotermIncludes} from "../../utils/model";

const ShipmentNormalizer = new class implements Normalizer<Shipment> {
    normalize(data: normalizedType<Shipment>): Shipment {
        // @ts-ignore
        const resource = new Shipment(
            data.id,
            data.derudderReference,
            data.bookingNumber,
            data.originPlatform,
            data.destinationPlatform,
            data.etdRequested ? new Date(data.etdRequested) : null,
            data.etaRequested ? new Date(data.etaRequested) : null,
            data.transportationMode,
            data.trainNumber,
            data.loadingShip,
            data.blLtaCmr,
            data.flightNumber,
            data.client,
            data.containerizationType,
            data.deliveryDeadline,
            data.unloadingPlatform,
            data.loadingPlatform,
            data.customeos ?? [],
            data.imposedCompany ?? '',
            data.invoices,
            data.comments,
            data.incoterms,
            data.cargoes,
            data.traficCode,
            data.transportReference || '', // todo
            data.workflowEntrustUsWith,
            data.cancelled,
            data.reference,
            data.customerOnProvision,
            data.archived,
            data.dispute,
            data.destuffingPlannedDate ? new Date(data.destuffingPlannedDate) : null,
            data.destuffingEndDate ? new Date(data.destuffingEndDate) : null,
            data.loadingEstimatedDate
                ? new Date(data.loadingEstimatedDate)
                : null,
            data.loadingRealDate
                ? new Date(data.loadingRealDate)
                : null,
            data.warehouse,
            data.tractionOperator,
            data.insurance,
            data.transportIcvNumber,
            data.destuffingIcvNumber,
            data.travelNumber,
            data.filingNumber,
            data.userDerudder,
            data.originEstimatedDate ? new Date(data.originEstimatedDate) : null,
            data.originRealDate ? new Date(data.originRealDate) : null,
            data.destinationEstimatedDate ? new Date(data.destinationEstimatedDate) : null,
            data.destinationRealDate ? new Date(data.destinationRealDate) : null,
            data.unloadingRealDate ? new Date(data.unloadingRealDate) : null,
            data.unloadingEstimatedDate ? new Date(data.unloadingEstimatedDate) : null,
            data.unloadingInitialDate ? new Date(data.unloadingInitialDate) : null,
        );
        resource.destuffing = data.destuffing;
        resource.delivery = data.delivery;
        resource.requestedDate = data.requestedDate;
        resource.displayedSuppliers = data.displayedSuppliers || [];
        resource.transportFeNumber = data.transportFeNumber;
        resource.destuffingFeNumber = data.destuffingFeNumber;
        resource.tractionEstimatedDate = data.tractionEstimatedDate ? new Date(data.tractionEstimatedDate) : null;
        resource.tractionRealDate = data.tractionRealDate ? new Date(data.tractionRealDate) : null;
        resource.destuffingWarehouse = data.destuffingWarehouse;
        resource.basiDate = data.basiDate ? new Date(data.basiDate) : null;
        resource.vaqDate = data.vaqDate ? new Date(data.vaqDate) : null;
        resource.copiesReceivedOn = data.copiesReceivedOn ? new Date(data.copiesReceivedOn) : null;
        resource.originalsReceivedOn = data.originalsReceivedOn ? new Date(data.originalsReceivedOn) : null;
        resource.latestDepartedShip = data.latestDepartedShip;
        resource.unloadingPredictiveDate = data.unloadingPredictiveDate ? new Date(data.unloadingPredictiveDate) : null;

        if (!data.workflowEntrustUsWith && incotermIncludes([ShipmentIncotermsConstants.FOB], data)) {
            resource.workflowEntrustUsWith = [
                ShipmentEntrustWithConstants.POST_DELIVERY,
                ShipmentEntrustWithConstants.ARRIVAL_CUSTOMS,
                ShipmentEntrustWithConstants.FREIGHT,
            ];
        } else if (!data.workflowEntrustUsWith && incotermIncludes([ShipmentIncotermsConstants.CIF], data)) {
            resource.workflowEntrustUsWith = [
                ShipmentEntrustWithConstants.POST_DELIVERY,
                ShipmentEntrustWithConstants.ARRIVAL_CUSTOMS,
            ];
        } else {
            resource.workflowEntrustUsWith = data.workflowEntrustUsWith ?? [];
        }

        return resource;
    }

    serialize(data: Partial<Shipment>, context: any): serializedType<Shipment> {
        switch (context) {
            case'dossier':
                return {
                    archived: data.archived ?? false,
                    customerOnProvision: data.customerOnProvision ?? false,
                    destuffing: data.destuffing ?? false,
                    delivery: data.delivery ?? false,
                    destuffingEndDate: data.destuffingEndDate ?? null,
                    destuffingPlannedDate: data.destuffingPlannedDate ?? null,
                    workflowEntrustUsWith: data.workflowEntrustUsWith ?? [],
                    transportFeNumber: data.transportFeNumber ?? null,
                    destuffingFeNumber: data.destuffingFeNumber ?? null,
                    filingNumber: data.filingNumber ?? null,
                    insurance: data.insurance ?? false,
                    transportIcvNumber: data.transportIcvNumber ?? null,
                    destuffingIcvNumber: data.destuffingIcvNumber ?? null,
                    transportationMode: data.transportationMode ?? null,
                    destuffingWarehouse: data.destuffingWarehouse ?? null,
                };
            default:
                return data;
        }
    }
}();

export {ShipmentNormalizer};
